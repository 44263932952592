import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import recovery from "./recovery";
import registration from "./registration";
import axs from "@/service/AXS.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clientID: localStorage.getItem("clientId") || null,
    emailToken: null,
    isNextSms: false,
    isOnlySms: false,
    user: {},
    projectUrl: process.env.VUE_APP_PROJECTURL,
    atomUrl: process.env.VUE_APP_ATOM_URL,
    icebreakerUrl: process.env.VUE_APP_ICE_URL,
    vkOptions: {
      vk_client_id: process.env.VUE_APP_VK_CLIENT_ID,
      vk_client_secret: process.env.VUE_APP_VK_CLIENT_SECRET,
      vk_redirect_url: process.env.VUE_APP_VK_REDIRECT_URL,
    },
    errorModal: {
      show: false,
      errorText: "",
      showBtn: false
    },
    redirectUrl: "",
    settingsFiles: null,
  },
  getters: {
    getClientId(state) {
      return state.clientID;
    },
    getProjectUrl(state) {
      return state.projectUrl;
    },
    getAtomUrl(state) {
      return state.atomUrl;
    },
    getIcebreakerUrl(state) {
      return state.icebreakerUrl;
    },
    getVkOptions(state) {
      return state.vkOptions;
    },
    getEmailToken(state) {
      return state.emailToken;
    },
    getIsNextSms(state) {
      return state.isNextSms;
    },
    getIsOnlySms(state) {
      return state.isOnlySms;
    },
    getUser(state) {
      let userStorage = localStorage.getItem("user");
      let user = state.user.hasOwnProperty()
        ? state.user
        : JSON.parse(userStorage);
      return user;
    },
    getErrorModal(state) {
      return state.errorModal;
    },
    getRedirectUrl(state) {
      return state.redirectUrl;
    },
    getSettingsFiles(state) {
      return state.settingsFiles;
    },
  },
  mutations: {
    setClientId(state, clientId) {
      localStorage.setItem("clientId", clientId);
      state.clientID = clientId;
    },
    setVkTemporaryToken(state, vkTemporaryToken) {
      localStorage.setItem("vkTemporaryToken", vkTemporaryToken);
    },
    setEmailToken(state, emailToken) {
      state.emailToken = emailToken;
    },
    setIsOnlySms(state, bool) {
      state.isOnlySms = bool;
      localStorage.setItem("isOnlySms", bool);
    },
    setIsNextSms(state, bool) {
      state.isNextSms = bool;
      localStorage.setItem("isNextSms", bool);
    },
    setUser(state, user) {
      state.user = { ...state.user, ...user };
      localStorage.setItem("user", JSON.stringify({ ...state.user, ...user }));
    },
    setErrorModal(state, { show, errorText, showBtn }) {
      state.errorModal = { show, errorText, showBtn };
    },
    setClearUser(state) {
      state.user = {};
      localStorage.removeItem("user");
    },
    setClearVkTemporaryToken(state) {
      localStorage.removeItem("vkTemporaryToken");
    },
    setRedirectUrl(state, val) {
      state.redirectUrl = val;
    },
    setSettingsFiles(state, val) {
      state.settingsFiles = val;

    },
  },
  actions: {
    aIsOnlySms({ commit }, bool) {
      let isOnlySms = localStorage.getItem("isOnlySms") || 'false';
      commit("setIsOnlySms", isOnlySms);
    },
    aIsNextSms({ commit }, bool) {
      let isNextSms = localStorage.getItem("isNextSms") || 'false';
      commit("setIsNextSms", isNextSms);
    },
    showError({ commit }, {errorText, showBtn}) {
      commit("setErrorModal", { show: true, errorText, showBtn });
    },
    hideError({ commit }) {
      commit("setErrorModal", { show: false, errorText: "", showBtn: false });
    },
    clearStore({ commit }) {
      commit("setRecoveryByEmail", 'false');
      commit("setIsOnlySms", 'false');
      commit("setIsNextSms", 'false');
      commit("setRecoveryStep", 1);
      commit("setRegistrationStep", 1);
      commit("setClearUser");
    },
    addRedirectUrl({ commit }, val) {
      commit("setRedirectUrl", val);
    },
    async addSettingsFiles({commit}) {
      try {
        const response = await axs.get("/settings/files");
        // console.log(JSON.parse(JSON.stringify(response.data)))
        commit("setSettingsFiles", JSON.parse(JSON.stringify(response.data)));
      } catch (error) {
        console.log(error);
      }
    },

  },

  modules: {
    auth,
    recovery,
    registration,
  },
});
