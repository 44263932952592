import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: `/authorization`,
    name: "authorization",
    component: () =>
      import(
        /* webpackChunkName: "authorization" */ "../views/AuthorizationView.vue"
      ),
  },
  {
    path: `/recovery`,
    name: "recovery",
    component: () =>
      import(/* webpackChunkName: "recovery" */ "../views/RecoveryView.vue"),
  },
  {
    path: `/registration`,
    name: "registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../views/RegistrationView.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let clientID = to.query.client_id || store.getters.getClientId;
  let emailToken = to.query.verification_token;
  let vkAccessCode = to.query.code;
  if(to.path === '/') {
    next('/authorization');
  }

  if (clientID) {
    store.commit("setClientId", clientID);
  } else {
    store.dispatch("showError", "Нет client ID");
  }

  if (emailToken) {
    store.commit("setEmailToken", emailToken);
  }

  if (vkAccessCode) {
    await store.dispatch("loginVk", vkAccessCode);
  }

  next();
});

export default router;
