import axios from "axios";
import store from "@/store";

const axs = axios.create({
baseURL: `${process.env.VUE_APP_API_HOST}`,
});

axs.interceptors.response.use(
function (response) {
	// statux2xx
	return response;
},
function (error) {
	let errorText = "";
	let showBtn = false;

	if (error.response.status === 444) {
		errorText =  "Данный VK ID еще не привязан ни к какому профилю. Авторизуйтесь для его привязки к вашему аккаунту";
		store.commit("setVkTemporaryToken", error.response.data?.vkTemporaryToken );
		store.dispatch("showError", {errorText, showBtn});
		throw error
	}

	switch (error.response.status) {
	case 430:
		errorText = "Пользователь не найден";
		break;
	case 431:
		errorText = "Введен не верный код подтверждения";
		break;
	case 432:
		errorText = "Истек срок кода подтверждения";
		break;
	case 433:
		errorText =
		"Превышено количество попыток введения кода, код заблокирован";
		break;
	case 434:
		errorText = "Превышено количество попыток отправки кода подтверждения";
		break;
	case 435:
		errorText = "Возникла ошибка. Попробуйте позже";
		break;
	case 436:
		errorText = "Возникла ошибка. Попробуйте позже";
		break;
	case 437:
		errorText = "Пользователь с указаным номером уже зарегистрирован";
		break;
	case 438:
		errorText = "Проверьте корректность заполнения данных";
		break;
	case 439:
		errorText = "Пользователь не зарегистрирован";
		break;
	case 440:
		errorText = "К вашей учетной записи привязан номер телефона, восстановите пароль с его помощью";
		break;
	case 441:
		errorText = "VK токен не действителен";
		break;
	case 442:
		errorText = "Восстановите пароль к вашему профилю";
		showBtn = true
		break;
	case 443:
		errorText = "Пользователь с указанным e-mail уже есть в базе";
		break;
	case 445:
		errorText = "Невалидный формат номера телефона";
		break;
	case 461:
		errorText =
		"Извините, в данный момент мы испытываем временные технические проблемы с отправкой SMS-кода. Пожалуйста, попробуйте позже.";
		break;
	default:
		errorText = "Ошибка соединения с сервером";
	}

	store.dispatch("showError", {errorText, showBtn});
	throw error
}
);

export default axs;
