import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSingleSelect from "vue-single-select";
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/style.css'

Vue.use(Vuelidate)
Vue.use(VueMask)

Vue.component('Drpdwn', VueSingleSelect);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
