<template>
	<div class="modal" v-if="getErrorModal.show">
		<div class="modal-window">
			<button type="button" class="page__close modal-window__close" @click="hideError">
					<svg width="26" height="26" viewBox="0 0 26 26">
						<use xlink:href="@/assets/images/sprite.svg#close"></use>
					</svg>
				</button>
			<div class="modal-window__inner">
				<h2 class="form__title modal-window__title">
					Возникла ошибка
				</h2>
				<p class="modal-window__descr">
					{{ getErrorModal.errorText }}
				</p>
				<button class="btn btn--bl modal-window__btn" v-if="getErrorModal.showBtn" @click="recoveryPass">
					Восстановить пароль
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			getErrorModal() {
				return this.$store.getters.getErrorModal
			}
		},
		methods: {
			hideError() {
				if (this.$route.query.code) {
					window.location.search = ''
				}
				this.$store.dispatch('hideError')
			},
			recoveryPass() {
				this.$store.dispatch('hideError')
				if (this.$router.currentRoute.name !== 'recovery') {
					this.$router.push({name: 'recovery'});
				}
			}
		}
	}
</script>