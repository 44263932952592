<template>
  <div id="app">
    <DefaultLayout />
    <Modal />
  </div>
</template>

<script>
import DefaultLayout from "@/views/layouts/DefaultLayout";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    DefaultLayout,
    Modal,
  },
  data: () => ({}),
};
</script>
r
