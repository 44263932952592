import store from "./index";
import axs from "@/service/AXS.js";

export default {
  state: {
    recoveryStep: 1,
    recoveryByEmail: false,
    isAttemptsRec: true,
  },
  getters: {
    getRecoveryStep(state) {
      return state.recoveryStep;
    },
    getRecoveryByEmail(state) {
      return state.recoveryByEmail;
    },
    getIsAttemptsRec(state) {
      return state.isAttemptsRec;
    },
  },
  mutations: {
    setIsAttemptsRec(state, val) {
      state.isAttemptsRec = val;
    },
    setRecoveryByEmail(state, bool) {
      localStorage.setItem("recovery-by-email", bool);
      state.recoveryByEmail = bool;
    },
    setRecoveryStep(state, num) {
      localStorage.setItem("recovery-step", num);
      state.recoveryStep = num;
    },
  },
  actions: {
    aRecoveryStep({
      commit
    }, num) {
      commit("setRecoveryStep", num);
    },
    aRecoveryByEmail({
      commit
    }, bool) {
      commit("setRecoveryByEmail", bool);
    },
    recoveryStep_1({
      commit
    }, {
      phoneNumber
    }) {
      return axs
        .post(
          `/authorization/recovery-password/phone/step-1/send-verification-code?client_id=${store.state.clientID}`, {
            phoneNumber,
          }, {
            headers: {},
          }
        )
        .then((response) => {

          if (response.data.attemptsLeftCall <= 1 && response.data.attemptsLeftSms <= 2) {
            commit("setIsNextSms", 'true');
          }
          if (response.data.attemptsLeftCall <= 0) {
            if (response.data.attemptsLeftSms <= 2) {
              commit("setIsOnlySms", 'true');
            }
            if (response.data.attemptsLeftSms <= 1) {
              commit("setIsAttemptsRec", false);
            }
          }

          commit("setUser", {
            phoneNumber,
            verificationToken: response.data.verificationToken,
          });
          commit("setRecoveryStep", 2);
        })
        .catch((error) => {
          if (error.response.status == 434) {
            commit("setIsAttemptsRec", false);
          }
          console.log(error);
        });
    },
    recoveryStep_2({
      commit
    }, {
      verificationToken,
      verificationCode
    }) {
      return axs
        .post(
          `/authorization/recovery-password/phone/step-2/verify-code?client_id=${store.state.clientID}`, {
            verificationToken,
            verificationCode,
          }, {
            headers: {},
          }
        )
        .then((response) => {
          if (!response.data.isFilledProfile) {
            commit("setUser", {
              birthDate: response.data.birthDate,
              city: response.data.city,
              email: response.data.email,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              region: response.data.region,
            });
          }

          commit("setUser", {
            verificationToken: verificationToken,
            isFilledProfile: response.data.isFilledProfile,

          });
          commit("setRecoveryStep", 3);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    recoveryStep_3({
      commit,
      state
    }, user) {
      let vkTemporaryToken = localStorage.getItem('vkTemporaryToken') || null;
      if (vkTemporaryToken) user.vkTemporaryToken = vkTemporaryToken
      return axs
        .post(
          `/authorization/recovery-password/phone/step-3/update-password?client_id=${store.state.clientID}`, {
            ...user
          }, {
            headers: {},
          }
        )
        .then((response) => {
          commit('setClearVkTemporaryToken')
          let redirectUrl = response.data.redirectUrl;
          window.location.replace(redirectUrl);
          commit("setRecoveryStep", 4);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    recoveryStep_email_1({
      commit
    }, {
      email,
      emailRecoveryToken
    }) {
      return axs
        .post(
          `/authorization/recovery-password/email/step-1/send-email?client_id=${store.state.clientID}`, {
            email,
            emailRecoveryToken,
          }, {
            headers: {},
          }
        )
        .then((response) => {
          if (!response) return
          commit("setRecoveryStep", 2);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    recoveryStep_email_2({
      commit
    }, {
      phoneNumber,
      emailRecoveryToken
    }) {
      return axs
        .post(
          `/authorization/recovery-password/email/step-2/send-verification-code?client_id=${store.state.clientID}`, {
            phoneNumber,
            emailRecoveryToken,
          }, {
            headers: {},
          }
        )
        .then((response) => {
          if (response.data.attemptsLeftCall <= 0) {
            commit("setIsOnlySms", true);
          }

          commit("setUser", {
            phoneNumber,
            emailRecoveryToken,
            verificationToken: response.data.verificationToken,
          });
          commit("setRecoveryStep", 2);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    recoveryStep_email_3({
      commit
    }, {
      verificationToken,
      verificationCode
    }) {
      return axs
        .post(
          `/authorization/recovery-password/email/step-3/verify-code?client_id=${store.state.clientID}`, {
            verificationToken,
            verificationCode,
          }, {
            headers: {},
          }
        )
        .then((response) => {
          if (response.data) {
            commit("setUser", {
              birthDate: response.data?.birthDate,
              city: response.data?.city,
              email: response.data?.email,
              firstName: response.data?.firstName,
              lastName: response.data?.lastName,
              region: response.data?.region,
            });
          }
          commit("setRecoveryStep", 3);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    recoveryStep_email_4({
      commit
    }, user) {
      let vkTemporaryToken = localStorage.getItem('vkTemporaryToken') || null;
      if (vkTemporaryToken) user.vkTemporaryToken = vkTemporaryToken

      return axs.post(`/authorization/recovery-password/email/step-4/update-password?client_id=${store.state.clientID}`, {
          ...user
        }, {
          headers: {},
        })
        .then((response) => {
          commit('setClearVkTemporaryToken')
          let redirectUrl = response.data.redirectUrl;
          window.location.replace(redirectUrl);
          commit("setRecoveryStep", 4);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};