import store from "./index";
import axs from "@/service/AXS.js";

export default {
  state: {
    regions: [],
    cities: [],
    registrationStep: 3,
    isAttemptsReg: true,
  },
  getters: {
    getRegions(state) {
      return state.regions;
    },
    getCities(state) {
      return state.cities;
    },
    getRegistrationStep(state) {
      return state.registrationStep;
    },
    getIsAttemptsReg(state) {
      return state.isAttemptsReg;
    },
  },
  mutations: {
    setIsAttemptsReg(state, val) {
      state.isAttemptsReg = val;
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setCitiesScroll(state, cities) {
      state.cities = state.cities.concat(cities);
    },
    setRegistrationStep(state, num) {
      localStorage.setItem("registration-step", num);
      state.registrationStep = num;
    },

    setAttemptsCall(state, val) {
      state.attemptsLeftCall = val;
    },
    setAttemptsSms(state, val) {
      state.attemptsLeftSms = val;
    },
  },
  actions: {
    aRegistrationStep({ commit }, num) {
      commit("setRegistrationStep", num);
    },
    uploadRegions({ commit }) {
      return axs
        .get(`/locations/regions?client_id=${store.state.clientID}&limit=100`)
        .then((response) => {
          commit("setRegions", response.data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadCities({ commit }, { offset, regionId, cityTitle, search }) {
      let url = `/locations/cities?client_id=${store.state.clientID}&region=${regionId}&limit=100&offset=${offset}`;
      url += cityTitle ? `&title=${cityTitle}` : ``;

      return axs
        .get(url)
        .then((response) => {
          if (!response.data.results.length > 0) {
            return;
          }

          if (search) {
            commit("setCities", response.data.results);
          } else {
            commit("setCitiesScroll", response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    registrationStep_1({ commit }, { phoneNumber }) {
      return axs
        .post(
          `/authorization/registration/step-1/send-verification-code?client_id=${store.state.clientID}`,
          {
            phoneNumber,
          },
          {
            headers: {},
          }
        )
        .then((response) => {
          if (response.data.attemptsLeftCall <= 1 && response.data.attemptsLeftSms <= 2) {
            commit("setIsNextSms", 'true');
          }
          if (response.data.attemptsLeftCall <= 0) {
            if(response.data.attemptsLeftSms <= 2) {
              commit("setIsOnlySms", 'true');
            }
            if(response.data.attemptsLeftSms <= 1) {
              commit("setIsAttemptsReg", false);
            } 
          }


          commit("setUser", {
            phoneNumber: phoneNumber,
            verificationToken: response.data.verificationToken,
          });
          commit("setRegistrationStep", 2);
        })
        .catch((error) => {
          if(error.response.status == 434) {
            commit("setIsAttemptsReg", false);
          } 
          console.log(error);
        });
    },
    registrationStep_2({ commit }, { verificationToken, verificationCode }) {
      return axs
        .post(
          `/authorization/registration/step-2/verify-code?client_id=${store.state.clientID}`,
          {
            verificationToken,
            verificationCode,
          },
          {
            headers: {},
          }
        )
        .then((response) => {
          commit("setRegistrationStep", 3);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    registrationStep_3({ commit }, user) {
      user.city = user.city.id
      return axs
        .post(
          `/authorization/registration/step-3/save-profile?client_id=${store.state.clientID}`,
          { ...user },
          { headers: {}, }
        )
        .then((response) => {
          commit('setClearVkTemporaryToken')
          let redirectUrl = response.data.redirectUrl;
          window.location.replace(redirectUrl);
          commit("setRegistrationStep", 4);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
