<template>
  <main class="page-main">
    <section class="page">
      <div class="page__inner container">
        <a class="page__logo logo" href="#" aria-label="logo">
          <svg width="458" height="86" viewBox="0 0 459 87">
            <use xlink:href="@/assets/images/sprite.svg#logo_atom"></use>
          </svg>
        </a>
        <div class="page__links page-links">
          <a class="page-links__item link-type-1" :href="getProjectUrl" target="_blank">Атомариум</a>
          <a class="page-links__item link-type-1" :href="getAtomtUrl" target="_blank">Атомный урок</a>
          <a class="page-links__item link-type-1" :href="getIcebreakerUrl" target="_blank">ледокол знаний</a>

        </div>
        <div class="page__controle">
          <button type="button" class="page__close" @click="abortAuth">
            <svg width="26" height="26" viewBox="0 0 26 26">
              <use xlink:href="@/assets/images/sprite.svg#close"></use>
            </svg>
          </button>
        </div>
        <router-view />
      </div>
    </section>
  </main>
</template>

<script>
export default {
  computed: {
    getProjectUrl() {
      return this.$store.getters.getProjectUrl;
    },
    getAtomtUrl() {
      return this.$store.getters.getAtomUrl;
    },
    getIcebreakerUrl() {
      return this.$store.getters.getIcebreakerUrl;
    }
  },
  methods: {
    abortAuth() {
      let redirectUrl = document.referrer ? document.referrer : this.getProjectUrl
      this.$store.dispatch("clearStore");
      window.location.replace(redirectUrl)
    },

    async checkSettingsFiles() {
      let settings = this.$store.getters.getSettingsFiles;
      if (settings === null) {
        await this.$store.dispatch("addSettingsFiles");       
      }
    },
  },

  created() {
    this.checkSettingsFiles();
  },
};
</script>
