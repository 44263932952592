import store from "./index";
import axs from "@/service/AXS.js";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    login({ commit }, { phoneNumber, password }) {
      let formData = new FormData();
      formData.append("phoneNumber", phoneNumber);
      formData.append("password", password);
      let vkTemporaryToken = localStorage.getItem('vkTemporaryToken');
      if(vkTemporaryToken) formData.append("vkTemporaryToken", vkTemporaryToken);

      return axs
        .post(
          `/authorization/login?client_id=${store.state.clientID}`,
          formData,
          {
            headers: {},
          }
        )
        .then((response) => {
          commit('setClearVkTemporaryToken')
          let redirectUrl = response.data.redirectUrl;
          window.location.replace(redirectUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginVk({ commit }, vkAccessCode) {
      return axs
        .post(
          `/authorization/vk?client_id=${store.state.clientID}`,
          { vkAccessCode, },
          {
            headers: {},
          }
        )
        .then((response) => {
          let redirectUrl = response.data.redirectUrl;
          window.location.replace(redirectUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
